import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  root: {
    borderRadius: "5px",
    fontSize: "15px",
    fontWeight: "bold",
    height: "47px",
    width: "250px",
    cursor: "pointer",
    transition: "all 120ms linear",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
})

const FuncButtonTheme = styled.button`
  & {
    color: ${({theme}) => theme.color};
    background: ${({theme}) => theme.bgColor};
    border: none;
  }
  &:hover {
    cursor: pointer;
    background: ${({theme}) => theme.bgColorHover};
  }
`;

const FuncButton = ({ classes, color, bgColor, bgColorHover, text, func, disabled}) => {
  return (
    <FuncButtonTheme
      className={classes.root}
      onClick={func}
      theme={{ color: color, bgColor: bgColor, bgColorHover: bgColorHover }}
      disabled={disabled}
    >
      {`${text}`}
    </FuncButtonTheme>
  )
}

FuncButton.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  bgColorHover: PropTypes.string,
}

FuncButton.defaultProps = {
  color: "FFFFFF",
  bgColor: "#FFBB02",
  bgColorHover: "#EDAD00",
}

export default withStyles(styles)(FuncButton)
