import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import CorporationItem from "./corporation-item"
import MoreButton from "./more-button"

const styles = theme => ({
  title: {
    color: "#181819",
    fontSize: "20px",
    marginBottom: "35px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      textAlign: "center",
    },
  },
})

const OtherCorporations = ({ classes, corporations }) => {
  return corporations.length > 0 && (
    <div style={{ marginBottom: "30px" }}>
      <h2 className={classes.title}>会社から決裁者を探す</h2>
      <div className="list">
        {corporations.map((corp, i) => (
          <CorporationItem
            item={corp}
            key={i}
            showBorderBottom={i !== corporations.length - 1}
            />
        ))}
      </div>
      <MoreButton url="/corporations" text="会社一覧へ" />
    </div>
  )
}

OtherCorporations.propTypes = {
  keymans: PropTypes.array,
  keymansJson: PropTypes.object,
}

export default withStyles(styles)(OtherCorporations)
