import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import ArrowRightKey from "../icons/arrow-right-key"

const styles = theme => ({
  more: {
    display: "flex",
    marginTop: "25px",
  },

  moreBtn: {
    display: "flex",
    alignItems: "center",
    color: "#000000",
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "none",

    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },

    "&:hover": {
      "& span": {
        textDecoration: "underline",
      },
    },
  },
})

const MoreButton = ({ classes, url, text }) => (
  <div>
    <div className={classes.more}>
      <div className="flex"></div>
      <Link className={classes.moreBtn} to={url}>
        <span style={{ marginRight: "10px" }} >{text}</span>
        <ArrowRightKey
          height={24}
          width={24}
          color="#000000"
        />
      </Link>
    </div>

  </div>
)

MoreButton.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  text: PropTypes.string,
}

export default withStyles(styles)(MoreButton)
