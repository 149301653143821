import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import FuncButton from "../atoms/func-button";
import { validateItems } from "../../utils/validator"

const styles = theme => ({
  header: {
    width: "100%",
    textAlign: "left",
    marginBottom: "5px",
  },

  dialogTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },

  dialogContent: {
    padding: "12px 32px",
  },

  row: {
    marginTop: '15px',
  },

  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  }
})

class IntentDownloadForm extends React.Component {
  state = {
    email: "",
    company: "",
    // position: "", 2024/07/16 CV数を上げる施策で「役職」をコメントアウト
    family_name: "",
    first_name: "",
    tel: "",
    error: {},
    isDownloadDone: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading === true && this.props.isLoading === false)
      if (this.props.errorMessage !== "") {
        this.setState({
          error: {email: this.props.errorMessage}
        });
      }

      if (prevProps.successMessage === "" && this.props.successMessage !== "") {
        this.setState({
          email: "",
          company: "",
          // position: "", 2024/07/16 CV数を上げる施策で「役職」をコメントアウト
          family_name: "",
          first_name: "",
          tel: "",
          error: {},
          isDownloadDone: true,
        });
        if (this.props.open === false) {
          this.props.toggleDialog();
        }
      }
    }

  handleChange = property => event => {
    const value = event.target.value;
    this.setState({
      [property]: value,
    });
  };

  validateForm = (state = {}) => {
    const error = validateItems(state);

    this.setState({
      error: error,
    });

    return error;
  };

  handleSubmit = () => {
    const { handleSubmit } = this.props;
    const { email, company,
      // position, 2024/07/16 CV数を上げる施策で「役職」をコメントアウト
      family_name, first_name, tel } = this.state;
    const error = this.validateForm({ email, company,
      // position, 2024/07/16 CV数を上げる施策で「役職」をコメントアウト
      family_name, first_name, tel });

    if (Object.keys(error).length === 0) {
      handleSubmit({
        email: email,
        company: company,
        // position: position,
        family_name: family_name,
        first_name: first_name,
        tel: tel,
      });
    }
  };

  onClose = () => {
    this.props.toggleDialog();
    setTimeout(() => { // 画面が閉じる前UI更新がされるのが一瞬見えるため、閉じるアニメーションが終わるまで待つ
      this.setState({
        isDownloadDone: false,
      });
    }, 100);
  }

  render() {
    const { classes, open, isLoading } = this.props;

    const { error } = this.state;
    return (
      <Dialog onClose={this.onClose} aria-labelledby="notification-modal" open={open}>
        <DialogTitle
          id="notification-modal-title"
          disableTypography
          className={classes.dialogTitle}
        >
          メールで無料ダウンロード
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <div className={classes.header}>
              <p>{this.state.isDownloadDone ? "「cs@keymanletter.com」からCSVファイルをメール添付で送信しました。ご確認くださいませ。" : "検索結果から最大50人のインテントデータを、ご入力いただいたメールアドレスにCSVファイルを添付して送信します。下記フォームをご入力ください。「*」は必須項目です。" }</p>
            </div>
            {!this.state.isDownloadDone && (
              <div>
                <TextField
                  id="email"
                  label="メールアドレス*(フリーメール不可)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="メールアドレス"
                  variant="outlined"
                  onChange={this.handleChange('email')}
                  helperText={error.email}
                  fullWidth
                  size="small"
                  className={classes.row}
                />
                <TextField
                  id="company"
                  label="会社名*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="例) ニッポン株式会社"
                  variant="outlined"
                  onChange={this.handleChange('company')}
                  helperText={error.company}
                  fullWidth
                  size="small"
                  className={classes.row}
                />
                {/* 2024/07/16 CV数を上げる施策で「役職」をコメントアウト */}
                {/* <TextField
                  id="position"
                  label="役職*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="例) マネージャー"
                  variant="outlined"
                  onChange={this.handleChange('position')}
                  helperText={error.position}
                  fullWidth
                  size="small"
                  className={classes.row}
                /> */}

                <div className={classes.nameContainer}>
                  <TextField
                    id="family_name"
                    label="姓*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="例) 山田"
                    variant="outlined"
                    onChange={this.handleChange('family_name')}
                    helperText={error.family_name}
                    fullWidth
                    size="small"
                    className={classes.row}
                  />

                  <TextField
                    id="first_name"
                    label="名*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="例) 太郎"
                    variant="outlined"
                    onChange={this.handleChange('first_name')}
                    helperText={error.first_name}
                    fullWidth
                    size="small"
                    className={classes.row}
                    style={{ marginLeft: "10px" }}
                  />
                </div>

                <TextField
                  id="tel"
                  label="電話番号*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="例) 0312345678"
                  variant="outlined"
                  onChange={this.handleChange('tel')}
                  helperText={error.tel}
                  fullWidth
                  size="small"
                  className={classes.row}
                />
                <div className={classes.submitButtonContainer}>
                  <FuncButton
                    color={"#FFFFFF"}
                    bgColor={isLoading ? "#B9B9B9" : "#FFBB02"}
                    bgColorHover={isLoading ? "#B9B9B9" : "#EDAD00"}
                    func={this.handleSubmit}
                    text={isLoading ? "ダウンロード中..." : "メールで無料ダウンロード"}
                    disabled={isLoading ? true : false}>
                  </FuncButton>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        {this.state.isDownloadDone && (
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

IntentDownloadForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(IntentDownloadForm)
