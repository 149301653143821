import {
  POST_CORPORATION_INTENT_CSV_REQUEST,
  POST_CORPORATION_INTENT_CSV_SUCCESS,
  POST_CORPORATION_INTENT_CSV_FAILURE,
} from "../../constants/action-types"
import { CorporationsApi } from "../api/corporations-api"
import moment from 'moment';

/*** POST /corporations/intents/download ***/
const postCorporationIntentDownloadRequest = () => ({
  type: POST_CORPORATION_INTENT_CSV_REQUEST,
})

const postCorporationIntentDownloadSuccess = (data) => ({
  type: POST_CORPORATION_INTENT_CSV_SUCCESS,
  payload: {
    data: data,
  },
})

const postCorporationIntentDownloadFailure = error => ({
  type: POST_CORPORATION_INTENT_CSV_FAILURE,
  payload: {
    error: error,
  },
})

export function postCorporationIntentDownload(data) {
  return async dispatch => {
    dispatch(postCorporationIntentDownloadRequest())

    CorporationsApi.postCorporationIntentDownload(data)
      .then(response => {
        dispatch(postCorporationIntentDownloadSuccess(response.data))
      })
      .catch(error => {
        dispatch(postCorporationIntentDownloadFailure(error))
      })
  }
}
