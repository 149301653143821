import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styled from 'styled-components';
import noImageWebp from "../../images/no-image.webp"
import { getRandom } from "../../utils/randomGenerator";

const styles = theme => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },

  img: {
    width: "100%",
    height: "auto",
    minWidth: "48px",
    maxWidth: "48px",
    display: "block",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      minWidth: "36px",
      maxWidth: "36px",
    },
  },

  rootBg: {
    padding: "22px 24px",
    height: "94px",
    [theme.breakpoints.down("xs")]: {
      padding: "14px 18px",
      height: "120px",
    },
  },

  rootSm: {
    padding: "8px 10px",
    height: "64px",
  },

  icon: {
    minWidth: "48px",
    width: "48px",
    height: "48px",
    marginRight: "14px",
    background: "#F2F6F9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "24px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "36px",
      width: "36px",
      height: "36px",
      borderRadius: "18px",
    },
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width: "calc(100% - 84px)",
  },

  header: {
    fontWeight: "bold",
    color: "#181819",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },

  headerBg: {
    fontSize: "16px",
    marginBottom: "7px",
  },

  headerSm: {
    fontSize: "13px",
    marginBottom: "5px",
  },

  keymanContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  companyName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },

  keymanAttribute: {
    marginLeft: "8px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
  },

  keymanMargin: {
    marginLeft: "8px",
  },

  position: {
    color: "#71737B",
    textOverflow: "ellipsis",
    fontSize: "12px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },

  keymanName: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },

  honorific: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },

  intentTagLabel: {
    fontSize: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },

  intentTag: {
    color: "rgb(26, 101, 255)",
    fontSize: "10px",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },

  blurGradientOverlay: {
    filter: "blur(5px)",
    overflow: "hidden",
    backdropFilter: "blur(3px)",
    height: "100%",
    width: "100%",
    background: "linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.9))",
  },

  descBg: {
    fontSize: "12px",
  },

  descSm: {
    fontSize: "11px",
  },

  headerBlock: {
    width: "35%",
    height: "16px",
    background: "#D8DBDF",
    marginBottom: "8px",
    borderRadius: "2px",
  },

  descBlock: {
    width: "90%",
    height: "12px",
    background: "#D8DBDF",
    borderRadius: "2px",
  },

  nameBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    overflow: "hidden",
  },

  name: {
    minWidth: "50%",
    display: "flex",
    flex: 1,
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  label: {
    color: "#5A5B62",
    background: "#F4F6F9",
    fontSize: "10px",
    padding: "4px 10px",
    whiteSpace: "nowrap",
    marginBottom: "7px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
});

const convertToHashtags = (tags) => {
  if (!tags) return '';
  return tags.split(',').map(tag => `#${tag.trim()}`).join(' ');
};

class IntentCorporationItem extends Component {
  static DUMMY_COMPANY_NAME = [
    "XxXx株式会社",
    "株式会社XxXx",
    "XXXX有限会社",
    "合同会社XxXx",
    "一般社団法人XXXX",
  ];

  static DUMMY_NAME =  [
    "XX XXX",
    "XXX XX",
    "X XXXX",
    "XXX XXX",
    "XX XX"
  ];

  static DUMMY_POSITION = [
    "代表取締役",
    "社長",
    "取締役",
    "部長",
    "課長",
    "執行役員",
  ];

  static DUMMY_INTENT_TAG = [
    ".NETC,ASP.NETCore,Koin,ASP.NET,MVCセッション,Invoice,ASP.NET,MVCTempData,Dapper,Terraform,Elasticsearch,EntityFramework",
    "データサイエンティスト,マーケティングリサーチ,経営陣,機械学習,データマイニング,データサイエンス,AI,データプラットフォーム,データ分析,統計学",
    "RPA,書評,通勤時間,拝島ライナー,運賃表,各駅,S-TRAIN,西武鉄道,物流,バリアフリー",
    "ラックマウントサーバー,24コア,マシンビジョン,ハードウェアRAID,サーバーラック,Deep Learning,ワークステーション,量子化学計算ソフト,エッジコンピューティング,計算科学",
    "ワーキングウェアブランド,レモンバターソテー,ガソリンターボエンジン,FreeSync,デジタルエシックス,VISAカード,NECパーソナルコンピュータ,日光街道,クォリティ,温暖化対策",
  ];

  constructor(props) {
    super(props);
    this.state = {
      dummyCompanyName: getRandom(IntentCorporationItem.DUMMY_COMPANY_NAME),
      dummyName: getRandom(IntentCorporationItem.DUMMY_NAME),
      dummyPosition: getRandom(IntentCorporationItem.DUMMY_POSITION),
      dummyIntentTag: getRandom(IntentCorporationItem.DUMMY_INTENT_TAG),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.setState({
        dummyCompanyName: getRandom(IntentCorporationItem.DUMMY_COMPANY_NAME),
        dummyName: getRandom(IntentCorporationItem.DUMMY_NAME),
        dummyPosition: getRandom(IntentCorporationItem.DUMMY_POSITION),
        dummyIntentTag: getRandom(IntentCorporationItem.DUMMY_INTENT_TAG),
      });
    }
  }

  render() {
    const { classes, item, small, showBorderBottom, isLoadingIntentCorps } = this.props;
    const { dummyName, dummyPosition, dummyCompanyName, dummyIntentTag} = this.state;
    let size = small ? "Sm" : "Bg";

    if (!item) {
      if (isLoadingIntentCorps) {
        return (
          <div
            className={classNames(classes.root, classes[`root${size}`])}
            style={{ pointerEvents: "none", borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }}
          >
            <div className={classes.icon} style={{ background: "#F2F6F9" }}></div>
            <div className={classes.container}>
              <div className={classes.headerBlock}></div>
              <div className={classes.descBlock}></div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={classNames(classes.root, classes[`root${size}`], classes.blurGradientOverlay)}
            style={{ pointerEvents: "none", borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }}
          >
            <div className={classes.icon}>
              <picture>
                <source
                  srcSet={noImageWebp}
                  height={48}
                  width={48}
                  className={classes.img}
                  type="image/webp"
                />
                <img src={noImageWebp} alt="logo" className={classes.img} width={48} height={48} />
              </picture>
            </div>
            <div className={classes.container}>
              <div className={classes.nameBlock}>
                <NameContainer className={classes.name}>
                  <span className={classNames(classes.header, classes[`header${size}`])}>
                    <span className={classes.keymanContainer}>
                      <span className={classes.companyName}>{dummyCompanyName}</span>
                      <span className={classes.keymanAttribute}>
                        <span className={classNames(classes.position, classes.keymanMargin)}>{dummyPosition}</span>
                        <span className={classNames(classes.keymanName, classes.blurGradientOverlay, classes.keymanMargin)}>{dummyName}</span>
                        <span className={classNames(classes.honorific, classes.keymanMargin)}>様</span>
                      </span>
                    </span>
                  </span>
                </NameContainer>
              </div>
              <div className={classNames(classes.desc, classes[`desc${size}`])}>
                <div className={classes.intentTag}>{convertToHashtags(dummyIntentTag)}</div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      const logoFileName = item.logo_path.split('.').slice(0, -1).join('.');
      return (
        <React.Fragment>
          <div className={classNames(classes.root, classes[`root${size}`])} style={{ borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }} >
            <div className={classes.icon}>
              <picture>
                <source
                  srcSet={logoFileName + ".webp"}
                  height={48}
                  width={48}
                  className={classes.img}
                  type="image/webp"
                />
                <img src={item.logo_path} alt="logo" className={classes.img} width={48} height={48} />
              </picture>
            </div>
            <div className={classes.container}>
              <div className={classes.nameBlock}>
                <NameContainer className={classes.name}>
                  <span className={classNames(classes.header, classes[`header${size}`])}>
                    <span className={classes.keymanContainer}>
                      <span className={classes.companyName}>{item.company_name}</span>
                      <span className={classes.keymanAttribute}>
                        <span className={classes.position}>{dummyPosition}</span>
                        <span className={classNames(classes.keymanName, classes.blurGradientOverlay, classes.keymanMargin)}>{dummyName}</span>
                        <span className={classNames(classes.honorific, classes.keymanMargin)}>様</span>
                      </span>
                    </span>
                  </span>
                </NameContainer>
              </div>
              <div className={classNames(classes.desc, classes[`desc${size}`])}>
                <div className={classes.intentTagLabel}>【インテントデータ】</div>
                <div className={classes.intentTag}>{convertToHashtags(item.corporation_intent_tags)}</div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const NameContainer = styled.div`
  & {
    text-decoration: unset;
    color: #000000;
  }
`;

IntentCorporationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  small: PropTypes.bool,
  showBorderBottom: PropTypes.bool,
};

IntentCorporationItem.defaultProps = {
  small: false,
  showBorderBottom: true,
};

export default withStyles(styles)(IntentCorporationItem);
