import React from "react"
import PropTypes from "prop-types"

/**
 * 「→」のアイコン
 */
const ArrowRightKey = props => {
  const { color } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.58" height="13.422" viewBox="0 0 14.58 13.422"
    >
    <g transform="translate(-2.294 -1.518)">
      <line x2="11.195" transform="translate(3.294 8.229)" fill="none" stroke={color} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
      <path d="M10.94,1l5.3,5.3-5.3,5.3" transform="translate(-0.362 1.932)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
  )
}

ArrowRightKey.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

ArrowRightKey.defaultProps = {
  color: "#000000",
  width: 24,
  height: 24,
}

export default ArrowRightKey
