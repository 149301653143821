const getRandomPart = (parts) => {
  return parts[Math.floor(Math.random() * parts.length)];
};

const getRandomParts = (parts, count) => {
  // countをpartsの長さに合わせて調整
  const adjustedCount = Math.min(count, parts.length);
  // Math.random()は0から1の間のランダムな浮動小数点数を生成。[0.5 - Math.random()]の結果は、-0.5から0.5の範囲になり、
  // この値が負の場合、sort()は要素の順序を逆にし、正の場合は順序を維持します。このランダム性によって、配列がシャッフルされる。
  const shuffled = parts.slice().sort(() => 0.5 - Math.random());
  return shuffled.slice(0, adjustedCount);
};

export const getRandom = (parts, count = 1) => {
  if (count === 1) {
    return getRandomPart(parts);
  } else {
    return getRandomParts(parts, count);
  }
}
