import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import { INTENT_PER_PAGE } from "../../constants/constants"
import Input from "@material-ui/core/Input"
import InputAdornment from "@material-ui/core/InputAdornment"

import Search from "../icons/search"
import { navigate } from "@reach/router"
import { getQueryVariable } from "../../utils/str"
import { getRandom } from "../../utils/randomGenerator";

const styles = theme => ({
  root: {
    background: "#0D2149",
    color: "#FFFFFF",
  },

  container: {
    width: "calc(100% - 340px)",
    maxWidth: "1100px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "40px 0",
    [theme.breakpoints.between("xs", "md")]: {
      width: "calc(100% - 150px)",
    },

    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 80px)",
    },
  },

  header: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "25px",
    color: "#FFFFFF",
    textAlign: "center",

    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "26px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },

  desc: {
    fontSize: "15px",
    marginBottom: "25px",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "400",

    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },

  searchRoot: {
    maxWidth: "527px",
    width: "80%",
    height: "48px",
    alignItems: "center",
    border: "1px solid #FFFFFF14",
    borderRadius: "24px",
    background: "#FFFFFF14",
    padding: "0px 5px 0px 15px",
    "& path": {
      fill: "rgba(255,255,255,0.38)",
    },

    [theme.breakpoints.down("sm")]: {
      height: "42px",
    },
  },

  searchInput: {
    background: "none",
    border: "none",
    color: "#FFFFFF",
    fontSize: "15px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 12px",
    '&::placeholder': {
      fontSize: '15px',
      [theme.breakpoints.down("sm")]: {
        fontSize: '10px',
      }
    },
  },

  searchFocused: {
    border: "1px solid #FFFFFF",
    "& path": {
      fill: "#FFFFFF",
    },
  },

  suggestKeywordsContainer: {
    width: "80%",
    maxWidth: "527px",
    paddingTop: "10px",
  },

  suggestKeyword: {
    display: "inline-block",
    color: "#FFFFFF",
    fontSize: "15px",
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "20px",
    cursor: "pointer",
    background: "#FFFFFF14",
    "&:hover": {
      background: "#EDAD00",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      padding: "4px 8px",
      margin: "4px",
    },
  
  },
})

class IntentSearchSection extends React.Component {
  static SAMPLE_KEYWORDS = [
    "デジタルトランスフォーメーション",
    "AI",
    "採用",
    "セキュリティ",
    "システム開発",
    "クラウド",
    "営業",
    "SaaS",
    "コスト削減",
    "アウトソーシング",
    "キャリア",
    "研修",
    "マネジメント",
    "財務",
    "リスク管理",
    "ガバナンス",
    "CSR",
    "サステナブル",
    "インフラ",
    "通信",
  ];

  static SUGGEST_COUNT = 5;

  initialState = {
    intentKeyword: "",
    isMobile: false,
    suggestKeywords: [],
  }

  constructor(props) {
    super(props)
    this.state = { ...this.initialState, suggestKeywords: getRandom(IntentSearchSection.SAMPLE_KEYWORDS, IntentSearchSection.SUGGEST_COUNT) };
  }

  handleChange = e => {
    this.setState({
      intentKeyword: e.target.value,
    })
  }

  handleEnterKeyPressed = e => {
    if (e.keyCode === 13) {
      if (this.state.intentKeyword) {
        this.getIntentCorporations(this.state.intentKeyword)
      }
    }
  }

  handleClickSuggestKeyword = e => {
    if (e.target.innerText) {
      const keyword = e.target.innerText.replace("#", "");
      this.setState({
        intentKeyword: keyword,
        suggestKeywords: getRandom(IntentSearchSection.SAMPLE_KEYWORDS, IntentSearchSection.SUGGEST_COUNT)
      });
      this.getIntentCorporations(keyword)
    }
  }

  getIntentCorporations = (keyword) => {
    this.props.getIntentCorporations({
      paging: 1,
      per_page: INTENT_PER_PAGE,
      page: 1,
      intent_keyword: keyword,
    }, true)
    // URLに反映
    navigate(`intent-search?intent_keyword=${keyword}`)

    this.props.setSearchKeyword(keyword);
  }

  componentDidMount() {
    // index.cssで、windowサイズが430px未満であれば改行して表示する
    if (typeof window !== 'undefined' && window.innerWidth < 430) {
      this.setState({
        isMobile: true,
      })
    }

    const intentKeyword = getQueryVariable(this.props.location.search, "intent_keyword")
    if (intentKeyword !== undefined) {
      // stateに値がないが、URLに値がある場合はURLの値をstateにセット
      this.setState({
        intentKeyword
      })
      this.getIntentCorporations(intentKeyword);
      this.props.setSearchKeyword(intentKeyword)
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <h1 className={classes.header}>企業のインテントデータを検索</h1>
          <h2 className={classes.desc}>
            20億件の興味関心データからあなたのサービスを「今」求めている企業が見つかります！
          </h2>
          <Input
            type="search"
            placeholder="興味関心キーワードを入力してください Ex生成AI"
            value={this.state.intentKeyword}
            onChange={this.handleChange}
            onKeyDown={this.handleEnterKeyPressed}
            disableUnderline={true}
            startAdornment={
              <InputAdornment position="start" style={{ margin: 0 }}>
                <Search />
              </InputAdornment>
            }
            classes={{
              root: classes.searchRoot,
              input: classes.searchInput,
              focused: classes.searchFocused,
            }}
          />
          <div className={classes.suggestKeywordsContainer}>
            {this.state.suggestKeywords.map((suggestKeyword, i) => (
              <div className={classes.suggestKeyword} onClick={this.handleClickSuggestKeyword}>#{suggestKeyword}</div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

IntentSearchSection.propTypes = {
  classes: PropTypes.object.isRequired,
  getIntentCorporations: PropTypes.func.isRequired,
}

export default withStyles(styles)(IntentSearchSection)
