const isEmpty = (str) => {
  return !str || str.length === 0;
};

const isValidEmail = (email) => {
  return !email.match(/[^@]+@[^.]+\.[^.]+/)
};

const isValidPlusCharNotInclude = (email) => {
  return email.includes('+');
}

const isValidFreeMail = (email) => {
  return email.match(/@(gmail.com|yahoo.co.jp|hotmail.com|outlook.com|outlook.jp|hotmail.co.jp|live.jp|aol.jp|icloud.com|docomo.ne.jp|softbank.ne.jp|i.softbank.jp|ezweb.ne.jp|au.com|ymobile.ne.jp|nifty.com|sute.jp|otmail.jp|eay.jp|nekosan.uk|dea-21olympic.com|bbitq.com|yopmail.com|guerrillamail.(com|de|net|org|info|biz)|guerrillamailblock.com|pokemail.net|sharklasers.com|spam4.me|grr.la|my10minutemail.com|qq.com|trashmail.(fr|se)|with-ukraine.tokyo)$/);
}

const isValidTel = (tel) => {
  return !tel.match(/^[0-9]+$/);
}

export const validateItems  = (items) => {
  let error = {}
  for (var property in items) {
    switch (property) {
      case 'email':
        if (isEmpty(items[property])) {
          error[property] = "入力してください";
        } else if (isValidEmail(items[property])) {
          error[property] = '有効なメールアドレスを入力してください';
        } else if (isValidPlusCharNotInclude(items[property])) {
          error[property] = 'プラス記号（+）が含んでいないメールアドレスを入力してください';
        } else if (isValidFreeMail(items[property])) {
          error[property] = 'フリーメール、キャリアメール以外のメールアドレスを入力してください';
        }
        break;
      case 'tel':
        if (isEmpty(items[property])) {
          error[property] = "入力してください";
        } else if (isValidTel(items[property])) {
          error[property] = '数字のみで入力して下さい';
        }
        break;
      default:
        if (isEmpty(items[property])) {
          error[property] = "入力してください";
        }
        break;
    }
  }

  return error;
}