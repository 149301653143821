import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import * as actions from "../state/actions/keymans"
import { postCorporationIntentDownload } from "../state/actions/corporations"
import { INTENT_PER_PAGE, CORP_PER_PAGE } from "../constants/constants"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Banner from "../components/common/banner"
import IntentCorporationItem from "../components/common/intent-corporation-item"
import OtherCorporations from "../components/common/other-corporations"
import Card from "../components/common/card"
import LinkToUpdate from "../components/common/link-to-update"
import IntentSearchSection from "../components/common/intent-search-section"
import { Location } from "@reach/router"
import { addCommas, getQueryVariable } from "../utils/str"
import FuncButton from "../components/atoms/func-button";
import IntentDownloadForm from "../components/dialogs/intent-download-form";
import ArrowRightKey from "../components/icons/arrow-right-key"

const mapStateToProps = (state, ownProps) => {
  return {
    isLoadingCorps: state.home.isLoadingCorps,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.intentCorporations.isInitialized,
    isLoadingIntentCorps: state.intentCorporations.isLoadingIntentCorps,
    intentCorporations: state.intentCorporations.intentCorporations.toIndexedSeq().toArray(),
    keymanTotalCountInIntentCorporations: state.intentCorporations.keymanTotalCountInIntentCorporations,
    isLoadingCorporationIntentDownload: state.corporations.isLoadingCorporationIntentDownload,
    corporationIntentDownloadErrorMessage: state.corporations.corporationIntentDownloadErrorMessage,
    corporationIntentDownloadSuccessMessage: state.corporations.corporationIntentDownloadSuccessMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIntentCorporations: (data, overwrite) =>
      dispatch(actions.getIntentCorporations(data, overwrite)),
    postCorporationIntentDownload: (data) => dispatch(postCorporationIntentDownload(data)),
  }
}

const styles = theme => ({
  footerButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-end",
    },
  },

  moreContainer: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "25px",
    }
  },

  more: {
    display: "flex",
  },

  moreBtn: {
    display: "flex",
    alignItems: "center",
    color: "#000000",
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },

    "&:hover": {
      "& span": {
        textDecoration: "underline",
      },
    },
  },

  buttonMargin : {
    marginBottom: "18px",
  },

  subheading: {
    fontWeight: "bold",
    color: "#5A5B62",
    marginBottom: "18px",
  },

  policy :{
    fontSize: "12px",
    color: "#000000",
    marginTop: "25px",
    marginBottom: "25px",
    backgroundColor: "#EFF7FF",
    padding: "10px",
  },
})

class IntentSearch extends React.Component {
  state = {
    showDownloadDialog: false,
    searchKeyword: "",
  }

  componentDidMount() {
  }

  toggleDownloadDialog = () => {
    this.setState({
      showDownloadDialog: !this.state.showDownloadDialog
    })
  }

  resolveDisplayIntentCorporations = () => {
    const { isInitialized, isLoadingIntentCorps, intentCorporations, keymanTotalCountInIntentCorporations } = this.props;
    if (!isInitialized || (isLoadingIntentCorps && intentCorporations.length === 0)) {
      return Array.from(Array(INTENT_PER_PAGE));
    }

    if (intentCorporations.length === 10) {
      return intentCorporations;
    }

    if (intentCorporations.length > 0 && intentCorporations.length < 10) {
      if (keymanTotalCountInIntentCorporations >= 10) {
        return intentCorporations.concat(Array.from(Array(INTENT_PER_PAGE - intentCorporations.length)));
      } else {
        return intentCorporations.concat(Array.from(Array(keymanTotalCountInIntentCorporations - intentCorporations.length)));
      }
    }

    return intentCorporations
  }

  render() {
    const {
      isLoadingIntentCorps,
      isInitialized,
      classes,
    } = this.props

    let intentCorporations = this.resolveDisplayIntentCorporations();

    let keymanTotalCountInIntentCorporations =
      (!isInitialized) ||
      (isLoadingIntentCorps && this.props.keymanTotalCountInIntentCorporations === 0)
        ? "-"
        : addCommas(this.props.keymanTotalCountInIntentCorporations);

    return (
      <Layout hasFooterPopup={false} hasFooterBannerPopup={false} hasExecutiveSearchFromOther={false} hasExecutiveSearchFormCorporation={false}>
        <SEO
          title="決裁者データベース「キーマンデータベース」 - インテントデータ検索"
          useTitleTemplate={false}
          description={`大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」のインテントデータが無料で検索・ダウンロードできます。`}
          canonical="https://keyman-db.smart-letter.com/intent-search"
        />

        <Location>
        {({ location }) => (
          <IntentSearchSection
            location={location}
            getIntentCorporations={this.props.getIntentCorporations}
            setSearchKeyword={(keyword) => this.setState({ searchKeyword: keyword })}
          />
        )}
        </Location>

        <div className="container">

          <div className="list-container" style={{ marginTop: "35px" }}>
            <div style={{ width: "100%" }}>
              <h2 className="title">{(this.state.searchKeyword === undefined || this.state.searchKeyword === "") ? '検索結果' : `「${this.state.searchKeyword}」のインテントデータ検索結果`}</h2>

              {(() => {
                if (this.state.searchKeyword === "" || this.state.searchKeyword === undefined) {
                  return (
                    <div>キーワードを入力してください。</div>
                  )
                } else if (intentCorporations.length === 0) {
                  return (
                    <div>該当するインテントデータはありませんでした。</div>
                  );
                } else {
                  return (
                    <React.Fragment>
                      <div className={classes.buttonMargin}>
                        <p className={classes.subheading}>
                          {keymanTotalCountInIntentCorporations}人の決裁者が見つかりました
                        </p>
                        <FuncButton
                          color={"#FFFFFF"}
                          bgColor={"#FFBB02"}
                          bgColorHover={"#EDAD00"}
                          func={this.toggleDownloadDialog}
                          text={"メールで無料ダウンロード"}
                          disabled={isLoadingIntentCorps ? true : false}>
                        </FuncButton>
                      </div>
                      <div className="list">
                        {intentCorporations.map((intentCorporation, i) => (
                          <IntentCorporationItem
                            item={intentCorporation}
                            key={i}
                            isLoadingIntentCorps={isLoadingIntentCorps}
                            showBorderBottom={i !== intentCorporations.length - 1}
                          />
                        ))}
                      </div>

                      <div className={classes.footerButtons}>
                        <FuncButton
                          color={"#FFFFFF"}
                          bgColor={"#FFBB02"}
                          bgColorHover={"#EDAD00"}
                          func={this.toggleDownloadDialog}
                          text={"メールで無料ダウンロード"}
                          disabled={isLoadingIntentCorps ? true : false}>
                        </FuncButton>

                        {intentCorporations && intentCorporations.length === 10 && (
                          <div className={classes.moreContainer}>
                            <div className={classes.more}>
                              <div className="flex"></div>
                              <div className={classes.moreBtn}>
                                <span style={{ marginRight: "10px" }} onClick={this.toggleDownloadDialog} onKeyPress={(e) => { if (e.key === 'Enter') this.toggleDownloadDialog(); }} role="button" tabIndex="0">続きをダウンロードする</span>
                                <ArrowRightKey
                                  height={24}
                                  width={24}
                                  color="#000000"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )
                }
              })()}
              <div className={classes.policy}>※ オプトインされていない個人単位のインテントデータを取り扱うことは一切ありません。法的に利用することが許可された企業単位でのインテントデータのみを取得・取扱いすることをお約束しています。</div>
            </div>
          </div>
        </div>
        <IntentDownloadForm
          open={this.state.showDownloadDialog}
          toggleDialog={this.toggleDownloadDialog}
          handleSubmit={(data) => this.props.postCorporationIntentDownload({...data, intent_keyword: this.state.searchKeyword})}
          isLoading={this.props.isLoadingCorporationIntentDownload}
          errorMessage={this.props.corporationIntentDownloadErrorMessage}
          successMessage={this.props.corporationIntentDownloadSuccessMessage}
        />
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <IntentSearch {...locationProps} {...props} />}
    </Location>
  ))
)

